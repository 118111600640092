<template>
    <div class="list" :class="dynamicClass">
        <div v-for="item of list" :key="item.id">
            <div class="item">
                <div
                    class="finish-btn"
                    @click.stop="toggleFinishItem(item.id, item.finish)"
                >
                    <i class="el-icon-check" v-if="item.finish"></i>
                </div>
                <div
                    class="item-info"
                    @click.stop="openPopup(item.id)"
                    v-html="newLineFunc(item.todo)"
                >
                    <!-- {{ item.todo }} -->
                </div>
                <i
                    class="el-icon-delete delete-btn"
                    @click.stop="deleteItem(item.id)"
                ></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        taskList: {
            type: Array,
        },
        dynamicClass: {
            type: String,
        },
    },
    data() {
        return {
            list: [],
        };
    },
    watch: {
        taskList(val) {
            this.list = val;
        },
    },
    methods: {
        // 待办任务状态更改（是否完成）
        toggleFinishItem(id, finish) {
            finish = !finish;
            // this.finish = !this.finish;
            this.$emit("toggleFinishItem", id, finish);
        },
        openPopup(id) {
            this.$emit("openPopup", id);
        },
        deleteItem(id) {
            this.$emit("deleteItem", id);
        },
        newLineFunc(content) {
            if (content.indexOf("\n") >= 0) {
                return content.split("\n").join("<br>");
            }
            return content;
        },
    },
    mounted() {
        this.list = this.taskList;
    },
};
</script>
