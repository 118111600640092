<template>
    <div class="work-todolist">
        <div class="heading">
            <i
                class="el-icon-circle-plus add-action"
                @click="openPopup(null)"
            ></i>
            <span>待办任务（个人）</span>
        </div>
        <div style="max-height: 400px; overflow-y: auto">
            <List
                v-if="isReady"
                :taskList="topList"
                dynamicClass="list--top"
                @openPopup="openPopup"
                @deleteItem="deleteItem"
                @toggleFinishItem="toggleFinishItem"
            ></List>
            <List
                v-if="isReady"
                :taskList="normalList"
                dynamicClass="list--normal"
                @openPopup="openPopup"
                @deleteItem="deleteItem"
                @toggleFinishItem="toggleFinishItem"
            ></List>
            <button
                v-if="isShowFinsh"
                class="toggle-task-btn"
                @click="isShowFinishTask = !isShowFinishTask"
            >
                {{ isShowFinishTask ? "隐藏" : "展示" }}已完成任务
            </button>
            <List
                :taskList="finishList"
                dynamicClass="list--finish"
                v-if="isShowFinishTask && isReady"
                @openPopup="openPopup"
                @deleteItem="deleteItem"
                @toggleFinishItem="toggleFinishItem"
            ></List>
        </div>

        <Dialog
            :dialogFormVisible="dialogFormVisible"
            :formData="formData"
            @updateVisible="(val) => (dialogFormVisible = val)"
            @postItem="postItem"
        ></Dialog>
    </div>
</template>

<script>
import Dialog from "./component/dialog.vue";
import List from "./component/list.vue";
export default {
    components: { Dialog, List },
    data() {
        return {
            // isLoading: false, // 加载展示与否
            isReady: false,
            dialogFormVisible: false, // 弹窗打开与否
            isShowFinsh: false,
            isShowFinishTask: false,
            formData: {
                //弹窗表单数据
                id: null,
                todo: "",
                top: false,
                finish: false,
            },
            list: [],
            topList: [],
            normalList: [],
            finishList: [],
        };
    },
    methods: {
        openPopup(id) {
            this.dialogFormVisible = true;
            this.emptyData();
            if (id) {
                const item = this.list.find((item) => item.id === id);
                this.formData.id = item.id;
                this.formData.todo = item.todo;
                this.formData.top = item.top;
            }
        },
        emptyData() {
            this.formData.id = null;
            this.formData.todo = "";
            this.formData.finish = false;
            this.formData.top = false;
        },
        getTodoList() {
            this.$ajax("/account/get-todo").done((res) => {
                this.list = res;
                for (let item of this.list) {
                    if (item.important == 1) {
                        item.top = true;
                    } else {
                        item.top = false;
                    }
                }
                for (let item of this.list) {
                    if (item.status == 1) {
                        item.finish = false;
                    } else {
                        item.finish = true;
                    }
                }
                this.normalList = this.list.filter(
                    (item) => !item.top && !item.finish
                );
                this.topList = this.list.filter(
                    (item) => item.top && !item.finish
                );
                this.finishList = this.list.filter((item) => item.finish);
                if (this.finishList.length) {
                    this.isShowFinsh = true;
                } else {
                    this.isShowFinsh = false;
                }
                this.isReady = true;
            });
        },
        postItem() {
            // // this.$message.success("待办任务已更新");
            const { id = null, todo, top } = this.formData;
            const important = top ? 1 : 0;
            if (id) {
                let item = this.list.find((item) => item.id == id);
                if (item.todo == todo && item.top == top) {
                    this.dialogFormVisible = false;
                    return;
                }
                this.$ajax("/account/update-todo", {
                    id,
                    todo,
                    important,
                })
                    .done(() => {
                        this.$message.success("待办任务已更新");
                        this.getTodoList();
                    })
                    .always(() => {
                        this.dialogFormVisible = false;
                    });
            } else {
                this.$ajax("/account/add-todo", {
                    todo,
                    important,
                })
                    .done(() => {
                        // 更新成功
                        this.$message.success("待办任务已更新");
                        this.getTodoList();
                    })
                    .always(() => {
                        this.dialogFormVisible = false;
                    });
            }
        },
        deleteItem(id) {
            let that = this;
            this.$confirm("此操作将永久删除该链接, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    that.$ajax("/account/remove-todo", {
                        id,
                    }).done(() => {
                        that.$message.success("删除成功");
                        that.getTodoList();
                    });
                })
                .catch(function () {
                    that.$message.info("已取消删除");
                });
        },
        toggleFinishItem(id, finish) {
            let status = finish ? 0 : 1;
            this.$ajax("/account/update-todo", {
                id,
                status,
            }).done(() => {
                this.getTodoList();
            });
        },
    },
    mounted() {
        // 数据请求
        this.getTodoList();
    },
};
</script>

<style lang="less">
@import "../../less/abstracts/variables.less";
.work-todolist {
    .heading {
        display: flex;
        align-items: center;
    }
    .add-action {
        margin-right: 15px;
        font-size: 20px;
        color: @color-primary;
        cursor: pointer;
    }
    .item {
        padding: 0 30px 0 10px;
        display: flex;
    }
    .list--top .item {
        background-color: rgba(#eee, 0.7);
        font-weight: 500;
    }
    .list--finish .item .item-info {
        text-decoration: line-through;
        color: #999;
    }
    .item-info {
        flex: 1;
        padding: 15px 0;
        border-bottom: 1px solid #ddd;
    }
    .finish-btn {
        margin-top: 15px;
        margin-right: 25px;
        width: 18px;
        height: 18px;
        border: 1px solid #999;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        i {
            position: absolute;
            left: 0;
            top: 0;
            color: @color-primary;
            font-weight: 900;
        }
    }
    .delete-btn {
        margin-top: 15px;
        margin-left: 25px;
        color: #f56c6c;
        cursor: pointer;
    }
    .toggle-task-btn {
        display: inline-block;
        margin: 30px auto;
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;
        color: @color-primary;
        font-size: 16px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }
}
</style>
