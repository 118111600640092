<template>
    <div class="p-center">
        <div>欢迎使用 MML Worker</div>
        <div class="line">
            <div class="l">
                <div class="heading">待办任务（项目，他人指派）</div>
            </div>
            <div class="r">
                <work-todolist></work-todolist>
            </div>
        </div>
        <div class="line">
            <div class="l">
                <div class="heading">相关的信息（如关联的项目的进度等）</div>
            </div>
            <div class="r">
                <div class="heading">日程表</div>
            </div>
        </div>
    </div>
</template>

<style lang="less">
@import "../less/abstracts/variables.less";
.p-center {
    .line {
        display: flex;
        padding: 20px 0;
        .l,
        .r {
            flex: 1;
            align-self: flex-start;
            .heading {
                background-color: @bg-color-primary;
                padding: 10px;
                font-weight: 600;
            }
        }
        .r {
            border-left: 10px solid white;
        }
    }
}
</style>

<script>
import workTodolist from "./work-todolist/";
export default {
    components: { workTodolist },
    data() {
        return {};
    },
    methods: {},
};
</script>
